"use strict";

// During bootstrap, we write requires at top of this file which looks
// basically like:
// module.exports = [
//   {
//     plugin: require("/path/to/plugin1/gatsby-browser.js"),
//     options: { ... },
//   },
//   {
//     plugin: require("/path/to/plugin2/gatsby-browser.js"),
//     options: { ... },
//   },
// ]
module.exports = [];
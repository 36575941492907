//This handles authentication-related tasks and user session management in the application.

import { navigate } from '../../.cache/commonjs/gatsby-browser-entry';
import { setActiveChart, setActiveTerms, setActiveDate } from './chartService';
export const isBrowser = () => typeof window !== 'undefined'; //  checks whether the code is running in a browser environment.




//---------- Functions for login verification purposes ----------//

export const login = (user) => { // function that is used called when a user logs in. It takes a user object as an argument. It calls setUser to store the user object in local storage.
  if (user && user.token) {
    return setUser({
      username: user.username,
      userId: user.userid,
      token: user.token,
      clients: user.clients,
      client: user.client._id,
      role: user.role
    });
  }
  return false;
};


export const setUser = user => { // Sets the user object in local browser storage after a successful login
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user));

  if (!user || Object.keys(user).length === 0) { // If the user object is false or empty,Redirect to the login page
    navigate('/auth/login');
  }
};


export const isLoggedIn = () => { // function that checks whether a user is logged in by retrieving the user object from local storage and checking if it contains a token.
  const user = getUser();
  return user && user.token;
};

// --------------------//






export const getUser = () => // Retrieves the user object from local storage if it exists.
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {};


export const getSwitchUser = () => // Similar to getUser, this function retrieves the switch user object from local storage if it exists.
  isBrowser() && window.localStorage.getItem('switchUser')
    ? JSON.parse(window.localStorage.getItem('switchUser'))
    : {};


const setSwitchUser = user => { // This sets the switch user object in local storage. It behaves similarly to setUser,
  let switcherAccount = getSwitchUser();
  if(Object.keys(getSwitchUser()).length === 0){
    window.localStorage.setItem('switchUser', JSON.stringify(getUser()));
    window.localStorage.setItem('gatsbyUser', JSON.stringify(user));
  }else{
    window.localStorage.setItem('switchUser', JSON.stringify(switcherAccount));
    window.localStorage.setItem('gatsbyUser', JSON.stringify(user));
  }

  if (!user || Object.keys(user).length === 0) {
    navigate('/auth/login');
  }
};




export const switchlogin = (user) => { // is called when a user switches accounts. It takes a user object as an argument and calls setSwitchUser to store the switch user object in local storage.
  if (user && user.token) {
    return setSwitchUser({
      username: user.username,
      userId: user.userid,
      token: user.token,
      clients: user.clients,
      role: user.role
    });
  }
  return false;
};

export const switchBack = () => { // is called when a user switches back to their original account after switching accounts. 
  let switcherAccount = getSwitchUser();
  window.localStorage.setItem('switchUser', JSON.stringify({}));
  window.localStorage.setItem('gatsbyUser', JSON.stringify(switcherAccount));
};



export const logout = () => { // is called when a user logs out. It clears the user and switch user objects from local browser storage and performs additional cleanup tasks.
  setActiveChart({});
  setActiveTerms({});
  setActiveDate({});
  setTimeout(() => {
    setUser({});
    window.localStorage.setItem('ACCESS_TOKEN', ''); 
    window.localStorage.setItem('REFRESH_TOKEN', ''); 
    window.localStorage.setItem('AUTH_CODE', '');
    window.localStorage.setItem('companyID', '');

    window.localStorage.setItem('switchUser', JSON.stringify({}));
  });
};
